// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Obfuscate from 'react-obfuscate'
import Analytics from '../utils/analytics'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import ContactForm from '../components/ContactForm'
import PaddedContent from '../components/PaddedContent'
import { type FluidWithWebp } from '../types'
import { Header, ContactFormContainer } from '../styles/pages/get-in-touch'

type Props = {
  location: any,
}

type StaticQueryData = {
  contentfulGetInTouchPage: {|
    pageTitle: string,
    keywords: Array<string>,
    description: {
      description: string,
    },
    headingBackgroundImage: {
      title: string,
      ...FluidWithWebp,
    },
    headingTitle: string,
    contactFormButtonLabel: string,
  |},
}

class GetInTouch extends React.Component<Props, *> {
  componentDidMount() {
    Analytics.pageview()
  }

  render() {
    const { location } = this.props

    return (
      <StaticQuery
        query={indexPageQuery}
        render={(data: StaticQueryData) => {
          const {
            pageTitle,
            keywords,
            description: { description },
            headingBackgroundImage,
            headingTitle,
            contactFormButtonLabel,
          } = data.contentfulGetInTouchPage

          return (
            <Layout>
              <SEO
                title={pageTitle}
                description={description}
                keywords={keywords}
                location={location}
              />
              <Header>
                <Header.Background>
                  <Img fluid={headingBackgroundImage.fluid} alt={headingBackgroundImage.title} />
                </Header.Background>
                <Nav location={location} />
                <PaddedContent>
                  <Header.Title>{headingTitle}</Header.Title>
                  <Header.Subtitle>
                    Fill out the form below or shoot us an email at{' '}
                    <Obfuscate
                      email="info@gothamstudios.com"
                      onClick={() => {
                        Analytics.event({
                          category: 'Outbound Navigation',
                          action: 'Click',
                          label: 'Get In Touch: Email Address',
                        })
                      }}
                    />
                  </Header.Subtitle>
                </PaddedContent>
              </Header>
              <ContactFormContainer>
                <ContactForm submitButtonLabel={contactFormButtonLabel} />
              </ContactFormContainer>
            </Layout>
          )
        }}
      />
    )
  }
}

export default GetInTouch

const indexPageQuery = graphql`
  query GetInTouchPageQuery {
    contentfulGetInTouchPage {
      pageTitle
      keywords
      description {
        description
      }
      headingBackgroundImage {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      headingTitle
      contactFormButtonLabel
    }
  }
`
