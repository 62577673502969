import styled from '@emotion/styled'
import { media } from '../dimensions'
import * as Fonts from '../fonts'
import Colors from '../colors'
import { LinkContainer } from '../../components/Button/style'

export const Header = styled.div`
  position: relative;
  padding-bottom: 48px;

  ${media.tablet`
    padding-bottom: 152px;
  `};
`

Header.Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

Header.Title = styled.h1`
  ${Fonts.Title};
  position: relative;
  margin: 0 0 24px;
  color: ${Colors.white};
  text-align: center;
`

Header.Subtitle = styled.h2`
  ${Fonts.Subtitle};
  text-align: center;
  color: ${Colors.white};
  max-width: 712px;
  margin: 0 auto 32px;

  a {
    ${Fonts.Link};
    color: ${Colors.white};
  }
`

Header.Buttons = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  ${LinkContainer} {
    margin-bottom: 16px;
  }

  ${media.tablet`
    flex-direction: row;
    justify-content: center;
    
    ${LinkContainer} {
      margin: 0 24px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  `};
`

export const ContactFormContainer = styled.div`
  padding: 72px 24px;

  ${media.desktop`
    width: 752px;
    margin: 0 auto;
    padding: 96px 0;
  `};
`
